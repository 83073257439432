import React from "react"

import Layout from "../components/Layout"
import Grid from "../components/Grid"
import css from "./privacy.module.scss"
import fontType from "../helpers/fontType.module.scss"

const metadata = {
  title: "Cookies | ReferralCandy",
  noindex: true,
}

const ReferralProgramCookiePolicy = (props) => {
  return (
    <Layout metadata={metadata} pathname={props.location.pathname}>
      <Grid className={css.container} centeredClassName={css.centered}>
        <h1 className={css.center}>Cookies</h1>

        <h2 className={css.center}>Who this policy is for</h2>
        <p>
          We’re ReferralCandy (also known as Anafore Pte. Ltd.). A store or
          service you love used us to setup their referral program. This page
          will explain what cookies they’ve enabled that you might encounter.
        </p>
        <p>
          All cookies are set to expire after 1 year, maximum. They usually
          expire before that deadline.
        </p>

        <h2 className={css.center}>Use of Cookies for the Referral Program</h2>

        <p className={`${fontType.b1} ${css.subheader}`}>
          Cookie preference - rc_cookie_consent
        </p>
        <p>
          This cookie is used to remember a visitor’s choice about cookies on
          ReferralCandy’s referral program offer pages. It is not accessible to
          third parties.
        </p>

        <p className={`${fontType.b1} ${css.subheader}`}>
          Fraud Prevention and Referral Detection - _rc_sp_network_cookie, cova,
          zeIwkNvD
        </p>
        <p>
          We collect data about your device to help credit you and your friend
          with successful referrals, and to prevent fraud in the store’s
          referral program. It is not accessible to third parties.
        </p>

        <p className={`${fontType.b1} ${css.subheader}`}>
          Analytics - Google Analytics, zeIwkNvD
        </p>
        <p>
          These cookies are used by the online store to collect information
          about how visitors use their referral program. They use the
          information to compile reports and to help them improve the referral
          program. The cookies collect information in an anonymous form,
          including the number of visitors to the referral program and where
          visitors have come to the website from.{" "}
          <a href="https://support.google.com/analytics/answer/6004245">
            Learn more about Google’s safeguarding of privacy
          </a>
        </p>

        <p className={`${fontType.b1} ${css.subheader}`}>
          Advertising Cookies - Adroll, Facebook, Google Ads
        </p>
        <p>
          These cookies are used by the online store to deliver ads relevant to
          you. They also limit the number of times that you see an ad and help
          the online store measure the effectiveness of their marketing
          campaigns. These are accessible to the ad provider only.
        </p>
      </Grid>
    </Layout>
  )
}

export default ReferralProgramCookiePolicy
